body, html {
  margin: 0;
  overflow: hidden;
  height:100%;
}
#left {
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

#sections {
  height: 100%;
  overflow-y: auto;
  padding: 0.5rem;
}

#right {
  height:100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 85%;
  overflow-y: scroll;
}
